import React, { useState, useEffect, useRef } from 'react'
import {graphql, Link, navigate} from 'gatsby'
import Layout from '../containers/layoutSecure'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import PortableText from '../components/portableText'
import {toPlainText} from '../lib/helpers'
import {Formik} from 'formik';
import * as Yup from 'yup';
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {isUserInRegion} from '../services/auth'
import TextTruncate from 'react-text-truncate'
import ReactPaginate from 'react-paginate'
import sanityClient from '@sanity/client'

const client = sanityClient({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
    token: process.env.SANITY_READ_TOKEN,
    useCdn: false,
    apiVersion: '2022-03-01',
});


let pageItems = []
let numPerPage = 10

export const query = graphql`
    query searchPageQuery {
        pageInfo: sanitySearchResult(_id: {regex: "/(drafts.|)searchResult/"}) {
            _id
            title
            banner {
                asset {
                  url
                }
            }
            _rawBody(resolveReferences: {maxDepth: 5})
        }

        setting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }


        allBuildings: allSanityBuilding( filter: {slug: {current: {ne: null}}} ) {
            nodes {
                id
                title
                slug {
                    current
                }
                region
                _rawPageDescription(resolveReferences: {maxDepth: 5})

                mainReferences {
                    ...SanityFile
                    ...SanityLink
                }
                categories {
                    title
                    _rawDecription(resolveReferences: {maxDepth: 5})
                    region
                    subSections {
                        ...SanityFile
                        ...SanityLink
                        ... on SanitySubSection {
                            _key
                            _type
                            _rawDecription
                            title
                            region
                            sectionLinks {
                                ...SanityFile
                                ...SanityLink
                            }
                            files {
                                ... on SanityMainImage {
                                    _key
                                    _type
                                    alt
                                    ...SanityImage
                                }
                                ...SanityVideo
                            }
                        }
    
                        ... on SanityLightboxWithDetailPage {
                            _key
                            _type
                            _rawDecription(resolveReferences: {maxDepth: 5})
                            title
                            subTitle
                            sectionLinks {
                              ...SanityFile
                              ...SanityLink
                            }
                            files {                         
                                ... on SanityMainImage {
                                    _key
                                    _type
                                    alt
                                    asset{
                                        url
                                        mimeType
                                        originalFilename
                                    }
                                }
                                ...SanityVideo
                            }
                            region
                            sectionDetailPages {
                                title
                                slug {
                                    current
                                }
                                region
                            }
                        }
    
                        ...SanityCommonSection
    
    
                    }
                }
            }
        }

        allBasicPages: allSanityBasicPage {
            nodes {
                id
                title
                slug {
                    current
                }
                region
                _rawBody(resolveReferences: {maxDepth: 5})
            }
        }

        allSites: allSanitySite {
            nodes {
                id
                title
                slug {
                    current
                }
                region
                _rawPageDescription(resolveReferences: {maxDepth: 5})
                mainReferences {
                    ...SanityFile
                    ...SanityLink
                }
                categories {
                    title
                    region
                    _key
                    _type
                    _rawDecription(resolveReferences: {maxDepth: 5})
                    subSections {
                        ...SanityFile
                        ...SanityLink
                        ...SanityCommonSection
                    }
                }
            }
        }


        siteLanding: sanitySiteLanding(_id: { regex: "/(drafts.|)siteLanding/" }) {
            _id
            title
            subTitle
            _rawPageDescription(resolveReferences: {maxDepth: 5})
            mainReferences {
                ...SanityFile
                ...SanityLink
            }

            categories {
                title
                _rawDecription
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ...SanityLink
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }
                    ...SanityCommonSection
                    
                }
            }
        }

        buildingLanding: sanityBuildingLanding(_id: { regex: "/(drafts.|)buildingLanding/" }) {
            _id
            title
            subTitle
            _rawPageDescription(resolveReferences: {maxDepth: 5})
            mainReferences {
                ...SanityFile
                ...SanityLink
            }

            categories {
                title
                _rawDecription(resolveReferences: {maxDepth: 5})
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ...SanityLink
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }

                    ... on SanityLightboxWithDetailPage {
                        _key
                        _type
                        _rawDecription(resolveReferences: {maxDepth: 5})
                        title
                        subTitle
                        sectionLinks {
                          ...SanityFile
                          ...SanityLink
                        }
                        files {
                            ... on SanityVideo {
                                _key
                                _type
                                thumbnail {
                                  alt
                                  asset {
                                    url
                                  }
                                }
                                file {
                                  asset {
                                    url
                                    mimeType
                                    originalFilename
                                  }
                                }
                                region
                            }                          
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                asset{
                                    url
                                    mimeType
                                    originalFilename
                                }
                            }
                        }
                        region
                        sectionDetailPages {
                            title
                            slug {
                                current
                            }
                            region
                        }
                    }
                    ...SanityCommonSection

                }
            }
        }

        decorLanding: sanityDecorLanding(_id: { regex: "/(drafts.|)decorLanding/" }) {
            _id
            title
            subTitle
            _rawPageDescription(resolveReferences: {maxDepth: 5})
            mainReferences {
                ...SanityFile
                ...SanityLink
            }

            categories {
                title
                _rawDecription
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ...SanityLink
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }
                    ...SanityCommonSection

                }
            }
        }

        signageLanding: sanitySignageLanding(_id: { regex: "/(drafts.|)signageLanding/" }) {
            _id
            title
            subTitle
            _rawPageDescription(resolveReferences: {maxDepth: 5})
            mainReferences {
                ...SanityFile
                ...SanityLink
            }

            categories {
                title
                _rawDecription
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ... on SanityLink {
                                _key
                                _type
                                title
                                url
                                region
                            }
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }
                    
                    ...SanityVendorReference

                    ...SanityCommonSection

                }
            }
        }


        homepage: sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }) {
            categories {
                ... on SanityAccordion {
                _id
                title
                subSections {
                    ... on SanitySubSection {
                    title
                    sectionLinks {
                        ...SanityFile
                        ...SanityLink
                    }
                    region
                    files {
                        ... on SanityMainImage {
                        _key
                        _type
                        asset {
                            url
                            originalFilename
                        }
                        caption
                        }
                        ...SanityVideo
                    }
                    _id
                    _type
                    _rawDecription
                    }
                    ...SanityLink
                    ...SanityFile
                }
                region
                _rawDecription
                }
            }
        }

        allFiles: allSanityFileAsset {
            totalCount
            nodes {
                url
                title
                path
                _type
                _id
                originalFilename
                mimeType
            }
        }

    }
`

const SearchPage = props => {
    const {data, errors} = props

    const dispatch = useDispatch()
    const searchTerm = useSelector(state => state.searchTerm)
    const searchItems = useSelector(state => state.searchItems)
    const userInfo = useSelector(state => state.userInfo)
    const [currentPage, setCurrentPage] = useState(0)
    const searchInput = useRef(null)

    let userRegion = userInfo ? userInfo.region : ''

    console.log('--> page data: ', data)

    // useEffect(() => {
    //     // const query = '*[_type == "siteLanding" && _id == "siteLanding" && categories[].title match "DRIVE-THRU"] {...}'
    //     // const query = '*[_type == "sanity.fileAsset"] {...}'
    //     const query = '*[_type == "accordion"] {...}'

    //     client.fetch(query).then((items) => {
    //         console.log('----> useEffect > query: ', items)
    //     })
    // }, [])

    //---------make all sanity type to be on list
    let  allContents = [...data.allBuildings.nodes, ...data.allBasicPages.nodes, ...data.allSites.nodes]
                        .filter(item => isUserInRegion(userRegion, item.region))
                        .map(item => {
                            return {title: item.title || '', slug: item.slug.current || '/', description: item._rawPageDescription || item._rawBody, mainReferences: item.mainReferences || [], categories: item.categories || []}
                        }) || []

    // site landing page
    data.siteLanding.slug = '/site'
    data.siteLanding.description = data.siteLanding._rawPageDescription || ''
    allContents.push(data.siteLanding)

    // building landing page
    data.buildingLanding.slug = '/building'
    data.buildingLanding.description =  data.buildingLanding._rawPageDescription || ''
    allContents.push(data.buildingLanding)

    // decor landing page
    data.decorLanding.slug = '/decor'
    data.decorLanding.description = data.decorLanding._rawPageDescription || ''
    allContents.push(data.decorLanding)

    // signage landing page
    data.signageLanding.slug = '/signage'
    data.signageLanding.description = data.signageLanding._rawPageDescription || ''
    allContents.push(data.signageLanding)

    // // search files from Saanity file system
    // let newFormatFiles = data.allFiles.nodes.map(item => {
    //     item.title = item.originalFilename
    //     item.slug = `${item.url}/${item.originalFilename}`
    //     return item
    // })
    // allContents = [...newFormatFiles, ...allContents]
    // console.log('--> merge data: ', allContents)


    let _commonSection = []
    // query file from each upload
    let _files = []    
    let _merged = [...data.allBuildings.nodes, ...data.allSites.nodes, data.siteLanding, data.buildingLanding, data.decorLanding, data.signageLanding]
    for(const _data of _merged){
        // main references
        if(_data.mainReferences){
            for(const file of _data.mainReferences){
                if(file._type === 'fileComponent'){
                    file.page = _data.slug?.current || _data.slug || ''
                    _files.push(file)
                }
            }
        }
        // categories
        if(_data.categories){
            for(const category of _data.categories){
                // console.log('---> category: ', category)
                if(category.subSections){
                    for(const subSection of category.subSections){
                        // console.log('---> subSection: ', subSection)
                        if(!subSection) continue
                        if(subSection._type === 'fileComponent'){
                            subSection.page = _data.slug?.current || _data.slug || ''
                            _files.push(subSection)
                        }
                        if(subSection._type === 'subSection'){
                            // console.log('---> subSection: ', subSection)
                            for(const file of subSection.sectionLinks){
                                if(file._type === 'fileComponent'){
                                    file.page = _data.slug?.current || _data.slug || ''
                                    _files.push(file)
                                }
                            }
                        }
                        if(subSection._type === 'lightboxWithDetailPage'){
                            // console.log('---> subSection: ', subSection)
                        }
                        if(subSection._type === 'commonSection'){
                            // console.log('---> commonSection: ', subSection)
                            let _found = _commonSection.find(item => item._id === subSection._id)
                            if(!_found){
                                _commonSection.push(subSection)
                            }
                        }
                        
                    }
                }
            }
        }
    }

    // common section
    for(const _data of _commonSection){
        if(_data.links){
            for(const file of _data.links){
                if(file._type === 'fileComponent'){
                    file.page = _data.title
                    _files.push(file)
                }
                if(file._type === 'commonSubOrder'){
                    if(file.links){
                        for(const subOrderFile of file.links){
                            if(subOrderFile._type === 'fileComponent'){
                                subOrderFile.page = _data.title
                                _files.push(subOrderFile)
                            }
                        }
                    }
                }
            }
        }
    }
    
    let newFormatFiles = _files.map(item => {
        // item.title = item.file?.asset?.originalFilename || item.title
        // item.url = `${item.file?.asset?.url}/${item.file?.asset?.originalFilename}` || ''
        item.url = ''
        if(item.file?.asset?.url){
            item.url += item.file?.asset?.url
        }
        if(item.file?.asset?.originalFilename){
            item.url += `/${item.file?.asset?.originalFilename}`
        }
        delete item.description
        return item
    })
    allContents = [...newFormatFiles, ...allContents]
    // console.log('---> files: ', _files, _commonSection)
    
    

    
    useEffect(() => {
        showResult(searchTerm)
    }, [searchTerm])
    

    //------ pagination
    const _searchItems = searchTerm ? searchItems : allContents 
    let totalpages = Math.ceil(_searchItems.length / numPerPage)
    let startIndex = currentPage * numPerPage
    let endIndex = (startIndex + numPerPage) -1
    pageItems = _searchItems.filter((item, index) => {
        return index >= startIndex && index <= endIndex
    })
    let totalSearchItems = _searchItems.length
    // console.log('--> page items: ', pageItems, currentPage)


    const onSearchClick = () => {
        setCurrentPage(0)
        const value = searchInput.current ? searchInput.current.value : ''
        dispatch({type: 'SET_SEARCH', payload: value})
        
        showResult(value)
    }

    const showResult = (search) => {
        // sanity query all match
        const _search = search ? search.toLowerCase() : ''
        const filteredData = (_search || _search !='') ? allContents.filter(item => {
            const { title, description } = item

            // filter pages
            let isPageScope = title?.toLowerCase().includes(_search)
                || toPlainText(description).toLowerCase().includes(_search)
            
            // landing page filter
            let isLandingCategoryTitle = false
            let hasItem = []
            if(item.categories){
                let hasItem = item.categories.filter(item => (item.title?.toLowerCase().includes(_search) || toPlainText(item._rawDecription).toLowerCase().includes(_search)) && isUserInRegion(userRegion, item.region))
                isLandingCategoryTitle = hasItem && hasItem.length > 0 ? true : false
            }

            // main reference
            let isMainRef = false
            if(item.mainReferences){
                hasItem = item.mainReferences.filter(item => item.title?.toLowerCase().includes(_search) && isUserInRegion(userRegion, item.region))
                isMainRef = hasItem && hasItem.length > 0 ? true : false
            }

            // categories > subSection
            let isSubSection = false
            if(item.categories){
                for (const property in item.categories) {
                    // console.log('---> ', property, ' : ', item.categories[property]);

                    let _subSections = item.categories[property].subSections || []

                    // subSection
                    hasItem = _subSections.filter(item => {
                        let _isTitle = item.title?.toLowerCase().includes(_search) && isUserInRegion(userRegion, item.region)

                        let _isLinks = false
                        let _isFiles = false
                        let _isVender = false
                        let _filterd
                        switch(item._type){
                            case 'subSection':
                                _filterd = item.sectionLinks?.filter(item => item.title?.toLowerCase().includes(_search) && isUserInRegion(userRegion, item.region)) || []
                                _isLinks = _filterd && _filterd.length > 0 ? true : false

                                _filterd = item.files?.filter(item => {
                                    if(item._type === 'mainImage'){
                                        return item.caption?.toLowerCase().includes(_search)
                                    }
                                    if(item._type === 'video'){
                                        return item.title?.toLowerCase().includes(_search) && isUserInRegion(userRegion, item.region)
                                    }
                                    
                                }) || []
                                _isFiles = _filterd && _filterd.length > 0 ? true : false
                                break;

                            case 'commonSection': // extra except title
                                _filterd = item.links?.filter(item => item.title?.toLowerCase().includes(_search) && isUserInRegion(userRegion, item.region)) || []
                                _isLinks = _filterd && _filterd.length > 0 ? true : false
                                break;

                            case 'vendorReference':
                                _filterd = Object.keys(item.vendors)?.filter(key => item.vendors[key]?.toLowerCase().includes(_search)) || []
                                _isVender = _filterd && _filterd.length > 0 && isUserInRegion(userRegion, item.vendors?.region) ? true : false
                                break;
                                

                            default: break;
                        }

                        return (_isTitle || _isLinks || _isFiles || _isVender)
                    })
                    isSubSection = hasItem && hasItem.length > 0 ? true : false
                    if(isSubSection) break
                }

            }

            console.log('filter :', isPageScope, isLandingCategoryTitle, isSubSection)

            return (isPageScope || isLandingCategoryTitle || isMainRef || isSubSection)
        }) : allContents
        // set result to state
        dispatch({type: 'SET_SEARCH_RESULT', payload: filteredData})

        if(searchInput.current) searchInput.current.value = search // set value from header input 
    }

    const handleChange = (e) => {
        // searchInput.current.value = e.target.value
        // onSearchClick()
    }

    const handlePageClick = (page) => {
        setCurrentPage(page.selected)
    }

    
    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const pageInfo = (data || {}).pageInfo
    if (!pageInfo) {
        throw new Error(
        'Missing page info.'
        )
    }
    let  banner = pageInfo.banner && pageInfo.banner.asset ? pageInfo.banner.asset.url : ''
    let setting = (data || {}).setting

    return(
        <Layout>
            <SEO
                title={pageInfo.title}
                description={setting.description}
                keywords={setting.keywords}
            />


            {/* banner */}
            <div className='container container-adjust-p'>
                {
                    banner ? 
                    <div>
                        <img src={banner} alt={pageInfo.banner ? pageInfo.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                    </div>
                    : 
                    <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    </div>
                }
                {/* <div className="bg-brown" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div> */}
                <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight: 100 }}>
                </div>
            </div>

            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Search</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>

            {/* content */}
            <div className="container">

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="input-group">
                            <input ref={searchInput} type="text" className="form-control" placeholder="Search term" aria-label="Search input" aria-describedby="search-button" onChange={handleChange} defaultValue={searchTerm} />
                            <div className="input-group-append">
                                <button id="search-button" onClick={onSearchClick} className="btn btn-primary h-100"><FaSearch size={24} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-md-flex justify-content-md-end align-items-md-end mt-2 mt-md-0">
                        {startIndex+1} - {startIndex+pageItems.length} of {totalSearchItems || 0} results
                    </div>
                </div>

                <hr/>
                <div className="row">
                    <div className="col-12">

                        {
                            // -------- LIST --------
                            pageItems.map((item, i) => {
                                let itemLink = makeUrl(item.slug || item.url)
                                return(
                                    <div key={i} className="result-item mt-3">
                                        {/* <h5 className="mb-0 text-uppercase"><Link to={itemLink}>{item.title || ''}</Link></h5> */}
                                        <h5 className="mb-0 text-uppercase">
                                            <Link to={itemLink}>
                                                {/* {displayTitle(item.title, item._type)} */}
                                                {displayTitle(item)}
                                            </Link>
                                        </h5>

                                        {item.description && !['fileComponent'].includes(item._type) && 
                                            <TextTruncate
                                                line={3}
                                                element="span"
                                                truncateText="…"
                                                text={toPlainText(item?.description) || ''}
                                                // textTruncateChild={<a href="#">Read on</a>}
                                            />
                                        }

                                        {['fileComponent'].includes(item._type) && 
                                            item.page || ''
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>

                <hr/>
                <div className="row">
                    <div className="col-12">

                        <nav className="mb-5" aria-label="navigation">

                            <ReactPaginate
                                containerClassName='pagination d-inline-block d-md-flex justify-content-center'
                                breakLabel='...'
                                breakClassName='page-item disable'
                                breakLinkClassName='page-link'
                                pageCount={totalpages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                activeClassName='active'
                                pageClassName='page-item'
                                pageLinkClassName='page-link'
                                previousClassName='page-item'
                                nextClassName='page-item'
                                previousLinkClassName='page-link'
                                nextLinkClassName='page-link'
                                previousLabel='Prev'
                                nextLabel='Next'
                                initialPage={currentPage}
                                onPageChange={handlePageClick}
                                // onPageActive={}
                            />

                        </nav>

                    </div>
                </div>
                

            </div>           


        </Layout>
    )
}

const makeUrl = (slug) => {
    if(!slug) return ''
    if(slug.includes('https://') || slug.includes('http://')){
        return slug
    }
    else if(slug.charAt(0) === '/'){
        return slug
    }
    else{
        return '/' + slug
    }
}

const displayTitle = (item) => {
    if(['sanity.fileAsset', 'fileComponent'].includes(item._type)){
        return `File > ${item.title}`
    }
    else{
        return item.title || ''
    }
}

export default SearchPage